import * as Styled from './OxDoctorProfile.styled';

import React from 'react';
import { OxTextTagline } from 'src/components/OxTextTagline';
import { OxTextHeading } from 'src/components/OxTextHeading';
import { OxTextParagraph } from 'src/components/OxTextParagraph';

type TProps = {
    fluidImages?: any;
    label: string;
    title?: string;
    description: string;
    isFirst?: boolean;
    contentHeight?: (number | null)[];
    assetHeight?: (number | null)[];
};

export const OxDoctorProfile = ({
    fluidImages,
    label,
    title,
    description,
    isFirst,
    contentHeight,
    assetHeight
}: TProps): JSX.Element => (
    <Styled.Container>
        {fluidImages && <Styled.Asset fluidImages={fluidImages} assetHeight={assetHeight} />}
        <Styled.ContentWrapper isFirst={isFirst} contentHeight={contentHeight}>
            <Styled.Headers>
                {label && <OxTextTagline>{label}</OxTextTagline>}
                {title && <OxTextHeading>{title}</OxTextHeading>}
            </Styled.Headers>
            {title && <OxTextParagraph>{description}</OxTextParagraph>}
        </Styled.ContentWrapper>
    </Styled.Container>
);
