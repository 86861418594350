import styled, { css } from 'styled-components';
import { OxImage } from 'src/components/OxImage';
import { OxLinkWithLine } from 'src/components/OxLinkWithLine';
import { fluidSizing } from 'src/utils';
import { OxContainer } from 'src/components/OxContainer';

export const Container = styled(OxContainer)`
    position: relative;
    padding-left: 0;
    padding-right: 0;
`;

export const Image = styled(OxImage)(
    ({ theme }) => css`
        max-height: 100%;
        ${theme.breakpoints.only('xs')} {
            flex: 1;
        }
    `
);

export const Content = styled.div(
    ({ theme }) => css`
        text-align: center;

        ${fluidSizing([
            { prop: 'margin-left', values: [40, 50, 50] },
            { prop: 'margin-top', values: [40, null, null] },
            { prop: 'margin-right', values: [40, null, null] },
            { prop: 'width', values: [null, 295, 295] }
        ])}

        ${theme.breakpoints.up('s')} {
            align-items: flex-start;
            bottom: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            position: absolute;
            text-align: left;
        }
    `
);

export const Link = styled(OxLinkWithLine)(
    ({ theme }) => css`
        color: ${theme.colors.primary.main};
    `
);
