import styled, { css } from 'styled-components';
import { OxSelect } from 'src/components/OxSelect';
import { fluidSizing, toVw } from 'src/utils';

export const Container = styled.div`
    max-width: 100%;
    position: relative;
`;

export const Select = styled(OxSelect)(
    ({ theme }) => css`
        margin-bottom: ${toVw(20, 'xs')};

        ${theme.breakpoints.up('s')} {
            display: none;
        }
    `
);

export const List = styled.div<{
    height?: (number | null)[];
}>(
    ({ height }) => `
  display: flex;

  ${height && fluidSizing([{ prop: 'height', values: height }])}
`
);

export const Title = styled.h5(
    ({ theme }) => css`
        cursor: pointer;
        display: none;
        flex-shrink: 0;
        position: relative;
        text-orientation: mixed;
        writing-mode: vertical-rl;

        ${theme.breakpoints.up('s')} {
            display: block;
        }
    `
);

export const ContentWrapper = styled.div<{
    active?: boolean;
}>(
    ({ theme, active }) => css`
        display: none;
        flex: 1 1 0;
        height: fit-content;
        pointer-events: none;

        ${active &&
        css`
            display: block;
            flex-basis: 100%;
            pointer-events: unset;
        `}

        ${theme.breakpoints.up('s')} {
            display: block;
            transition: max-width 0.3s ease 0s;
            max-height: 0;
            max-width: 0;

            ${active &&
            css`
                max-height: unset;
                max-width: 100%;
            `}
        }
    `
);

export const Content = styled.div<{ active?: boolean }>(
    ({ theme, active }) => css`
        ${theme.breakpoints.up('s')} {
            opacity: 0;
            width: auto;
            transition: opacity 0.3s ease 0.3s;
            visibility: hidden;

            ${active &&
            css`
                opacity: 1;
                visibility: visible;
            `}
        }
    `
);
