import * as Styled from './OxQuoteSlider.styled';

import React, { useCallback, useEffect, useState } from 'react';
import { EAnimatedWrapperAnimation } from 'src/components/OxAnimatedWrapper';
import { useSwipeable } from 'react-swipeable';
import { IGatsbyImageData } from 'gatsby-plugin-image';

export type TQuoteItem = {
    quote?: string | JSX.Element;
    fluidImage?: IGatsbyImageData;
    source?: string;
    link?: string;
};

type TProps = {
    items: TQuoteItem[];
};

const intervalTimeout = 10000;
let interval: NodeJS.Timeout;

export const OxQuoteSlider = ({ items = [] }: TProps) => {
    const [activeQuote, setActiveQuote] = useState<number>(0);
    const [hovered, setHovered] = useState<boolean>(false);

    const nextQuote = (): void =>
        setActiveQuote((prev) => {
            return prev >= items.length - 1 ? 0 : prev + 1;
        });

    const prevQuote = (): void =>
        setActiveQuote((prev) => {
            return prev <= 0 ? items.length - 1 : prev - 1;
        });

    const slide = (): false | void => !hovered && nextQuote();

    const initInterval = (): void => {
        if (interval) clearInterval(interval);

        interval = setInterval(slide, intervalTimeout);
    };

    const eventHandlers = {
        onMouseOver: (): void => {
            setHovered(true);
        },
        onMouseLeave: (): void => {
            setHovered(false);
        }
    };

    const swiperHandlers = useSwipeable({
        onSwipedLeft: nextQuote,
        onSwipedRight: prevQuote
    });

    useEffect(() => {
        initInterval();
    }, [hovered]);

    useEffect(() => {
        // initInterval();

        return (): void => {
            clearInterval(interval);
        };
    }, []);

    const Image = useCallback((item: TQuoteItem) => {
        if (!item.fluidImage) return <></>;

        const Image = (
            <Styled.Image alt={item.source ?? item.quote} fluidImages={item.fluidImage} />
        );

        if (item.link) {
            return <Styled.Link to={item.link}>{Image}</Styled.Link>;
        }

        return Image;
    }, []);

    return (
        <Styled.Container {...swiperHandlers} {...eventHandlers}>
            <Styled.Quotes>
                {items.map((item, index) => (
                    <Styled.AnimatedWrapper
                        relativeBox
                        noAbsoluteOnInactive
                        key={index}
                        active={index === activeQuote}
                        animation={EAnimatedWrapperAnimation.FadeIn}
                        isNext={activeQuote === index - 1}
                    >
                        {item.quote && <Styled.Quote>&#8220;{item.quote}&#8221;</Styled.Quote>}
                        {Image(item)}
                        {item.source && !item.fluidImage && (
                            <Styled.Source>{item.source}</Styled.Source>
                        )}
                    </Styled.AnimatedWrapper>
                ))}
            </Styled.Quotes>
            {items.length > 1 && (
                <Styled.Controls>
                    {items.map((_item, index) => (
                        <Styled.Dot
                            key={index}
                            active={activeQuote === index}
                            onClick={() => setActiveQuote(index)}
                            title={`Move to slide ${index}`}
                        />
                    ))}
                </Styled.Controls>
            )}
        </Styled.Container>
    );
};
