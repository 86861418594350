import * as Styled from './OxHorizontalAccordion.styled';

import React, { useEffect, useState } from 'react';
import { EInputTheme, OxThemedInput } from 'src/components/OxThemedInput';

export type TAccordionItem = {
    title: string | React.ReactFragment;
    selectTitle: string;
    content: string | React.ReactFragment;
};

type TSelect = {
    title: string;
    value: number;
};

type TProps = {
    items: TAccordionItem[];
    height?: (number | null)[];
};

export const OxHorizontalAccordion = ({ items = [], height }: TProps) => {
    const [activeIndex, setActiveIndex] = useState<number>(0);

    useEffect(() => {
        setActiveIndex(0);
    }, [items]);

    return (
        <Styled.Container>
            <OxThemedInput theme={EInputTheme.Gold}>
                <Styled.Select
                    items={items.map(
                        (item, index): TSelect => ({
                            title: item.selectTitle,
                            value: index
                        })
                    )}
                    value={activeIndex}
                    onValueChange={(val: TSelect) => {
                        val && setActiveIndex(val.value);
                    }}
                    labelName="title"
                    valueName="value"
                />
            </OxThemedInput>
            <Styled.List height={height}>
                {items.map((item, index) => (
                    <React.Fragment key={index}>
                        <Styled.ContentWrapper active={index === activeIndex}>
                            <Styled.Content active={index === activeIndex}>
                                {item.content}
                            </Styled.Content>
                        </Styled.ContentWrapper>
                        <Styled.Title onClick={() => setActiveIndex(index)}>
                            {item.title}
                        </Styled.Title>
                    </React.Fragment>
                ))}
            </Styled.List>
        </Styled.Container>
    );
};
