import * as Styled from './OxFullBleedFeature.styled';

import React from 'react';
import { OxTextTagline } from 'src/components/OxTextTagline';
import { OxTextHeading } from 'src/components/OxTextHeading';
import { OxTextParagraph } from 'src/components/OxTextParagraph';

type TProps = {
    fluidImages?: any;
    tag?: string;
    heading?: string;
    description?: string;
    cta?: string;
    href?: string;
};

export const OxFullBleedFeature = ({
    fluidImages,
    tag,
    heading,
    description,
    cta,
    href
}: TProps): JSX.Element => {
    return (
        <Styled.Container>
            {fluidImages && <Styled.Image fluidImages={fluidImages} />}
            <Styled.Content>
                {tag && <OxTextTagline>{tag}</OxTextTagline>}
                {heading && <OxTextHeading>{heading}</OxTextHeading>}
                {description && <OxTextParagraph>{description}</OxTextParagraph>}
                {cta && <Styled.Link to={href}>{cta}</Styled.Link>}
            </Styled.Content>
        </Styled.Container>
    );
};
