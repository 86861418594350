import { fluidSizing } from 'src/utils';
import styled, { css } from 'styled-components';
import { OxImage } from 'src/components/OxImage';
import { OxTextParagraph } from 'src/components/OxTextParagraph';

export const Container = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;
        ${theme.breakpoints.up('m')} {
            flex-direction: row-reverse;
        }
    `
);

export const Asset = styled(OxImage)<{ assetHeight?: (number | null)[] }>(
    ({ theme, assetHeight }) => css`
        width: 100%;
        ${fluidSizing([{ prop: 'margin-bottom', values: [10, null, null] }])}
        ${theme.breakpoints.up('m')} {
            width: 50%;
            height: auto;
            margin-bottom: 0;
        }
        ${fluidSizing([{ prop: 'height', values: assetHeight ?? [300, 250, 450] }])}
    `
);

export const ContentWrapper = styled.div<{
    isFirst?: boolean;
    contentHeight?: (number | null)[];
}>(
    ({ theme, isFirst, contentHeight }) => css`
        width: 100%;

        ${fluidSizing([
            { prop: 'height', values: contentHeight ?? [null, 250, 450] },
            { prop: 'padding-left', values: isFirst ? [] : [0, 20, 30] },
            { prop: 'padding-right', values: [0, 20, 30] }
        ])}

        ${theme.breakpoints.up('s')} {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        ${theme.breakpoints.up('m')} {
            width: 50%;
        }
    `
);

export const Headers = styled.div(
    ({ theme }) => css`
        ${theme.breakpoints.only('xs')} {
            display: none;
        }
    `
);
