import * as Styled from './OxMediaCarousel.styled';

import React, { useState } from 'react';
import { OxContainer } from 'src/components/OxContainer';
import { OxLineHeader } from 'src/components/OxLineHeader';
import { OxTextParagraph } from 'src/components/OxTextParagraph';
import { useBreakpoints } from 'src/hooks';
import { useSwipeable } from 'react-swipeable';

type TProps = {
    heading?: string;
    items: {
        fluidImages?: any;
        text?: string;
        includeQuotes?: boolean;
        date?: string;
    }[];
};

export const OxMediaCarousel = ({ heading, items = [] }: TProps): JSX.Element => {
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const device = useBreakpoints();
    const itemsToDisplay = device.xs ? 1 : 3;

    const next = (): void =>
        setActiveIndex((prev) => (prev >= items.length - itemsToDisplay ? prev : prev + 1));

    const prev = (): void => setActiveIndex((prev) => (prev <= 0 ? 0 : prev - 1));

    const sharedArrowProps = {
        name: 'triangle',
        size: device.xs ? 20 : 100
    };

    const swiperHandlers = useSwipeable({
        onSwipedLeft: next,
        onSwipedRight: prev
    });

    return (
        <OxContainer>
            {heading && <OxLineHeader>{heading}</OxLineHeader>}
            <Styled.Carousel {...swiperHandlers}>
                {items.length > itemsToDisplay && !device.xs && (
                    <Styled.Arrow
                        {...sharedArrowProps}
                        disabled={activeIndex === 0}
                        onClick={prev}
                    />
                )}
                <Styled.List>
                    {items.map((item, index) => (
                        <Styled.Item
                            key={index}
                            active={index >= activeIndex && index < activeIndex + itemsToDisplay}
                        >
                            {item.fluidImages?.length > 0 && (
                                <Styled.Image objectFitContain fluidImages={item.fluidImages} />
                            )}
                            <OxTextParagraph>
                                {item.includeQuotes ? `“${item.text}”` : item.text}
                            </OxTextParagraph>
                            <OxTextParagraph>{item.date}</OxTextParagraph>
                        </Styled.Item>
                    ))}
                </Styled.List>
                {items.length > itemsToDisplay && !device.xs && (
                    <Styled.Arrow
                        {...sharedArrowProps}
                        reversed
                        disabled={activeIndex + itemsToDisplay === items.length}
                        onClick={next}
                    />
                )}
            </Styled.Carousel>
            {items.length > itemsToDisplay && (
                <Styled.MobileNav>
                    <Styled.Arrow
                        {...sharedArrowProps}
                        disabled={activeIndex === 0}
                        onClick={prev}
                    />
                    Swipe
                    <Styled.Arrow
                        {...sharedArrowProps}
                        reversed
                        disabled={activeIndex + itemsToDisplay === items.length}
                        onClick={next}
                    />
                </Styled.MobileNav>
            )}
        </OxContainer>
    );
};
