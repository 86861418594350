import * as Styled from './OxExperienceGrid.styled';

import React from 'react';
import { OxTextHeading } from 'src/components/OxTextHeading';
import { OxTextParagraph } from 'src/components/OxTextParagraph';

type TProps = {
    items: {
        title: string;
        description: string;
        image: any;
    }[];
};

export const OxExperienceGrid = ({ items = [] }: TProps): JSX.Element => (
    <Styled.List as="ul">
        {items.map((item, index) => (
            <Styled.Item key={index}>
                <Styled.Text>
                    <OxTextHeading>{item.title}</OxTextHeading>
                    <OxTextParagraph>{item.description}</OxTextParagraph>
                </Styled.Text>
                {item.image && <Styled.Image fluidImages={[item.image]} />}
            </Styled.Item>
        ))}
    </Styled.List>
);
