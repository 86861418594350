import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const BlockContent = require('@sanity/block-content-to-react');

type TProps = {
    blocks: any;
};

export const OxSanityBlockContent = ({ blocks }: TProps): JSX.Element => {
    const BlockRenderer = (props: any) => {
        if (props.children.length === 1 && props.children[0] === '') {
            props.children[0] = <span>&nbsp;</span>;
        }

        // Fall back to default handling
        return BlockContent.defaultSerializers.types.block(props);
    };

    const serializers = {
        types: { block: BlockRenderer }
    };

    return <BlockContent blocks={blocks} serializers={serializers} />;
};
