import styled, { css } from 'styled-components';
import { fluidSizing } from 'src/utils';
import { createTextStyle } from 'src/utils/createTextStyle';
import { EFontWeight, EFonts, ESizes } from 'src/config/enums';
import { hoverUnderlineAnimation } from 'src/styles/animations';

export const Container = styled.div(
    ({ theme }) => css`
        align-items: center;
        background: ${theme.colors.secondary.main};
        display: flex;
        flex-direction: column;
        justify-content: center;

        ${fluidSizing([
            {
                prop: 'padding',
                values: [ESizes.GutterXS, ESizes.GutterS, ESizes.GutterM]
            }
        ])}
    `
);

export const Buttons = styled.ul(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;

        ${fluidSizing([
            { prop: 'margin-bottom', values: [20, 40, 40] },
            { prop: 'margin-top', values: [10, 20, 20] }
        ])}

        ${theme.breakpoints.up('s')} {
            flex-direction: row;
        }
    `
);

export const Button = styled.li`
    text-transform: uppercase;
    ${fluidSizing([
        { prop: 'margin-bottom', values: [10, null, null] },
        { prop: 'margin-left', values: [20, 40, 40] },
        { prop: 'margin-right', values: [20, 40, 40] }
    ])}
`;

export const Subsection = styled.div(
    ({ theme }) => css`
        align-items: center;
        display: flex;
        flex-direction: column;

        ${createTextStyle(EFonts.Montserrat, EFontWeight.Normal, [12, 12, 12], [16, 14, 14])}

        ${theme.breakpoints.up('s')} {
            flex-direction: row;
        }
    `
);

export const SubsectionHeader = styled.div(
    ({ theme }) => css`
        font-weight: ${theme.fontWeights.bold};
        text-transform: uppercase;

        ${fluidSizing([
            { prop: 'margin-bottom', values: [10, null, null] },
            { prop: 'margin-left', values: [20, 20, 20] },
            { prop: 'margin-right', values: [20, 20, 20] }
        ])}
    `
);

export const SubsectionList = styled.dl(
    ({ theme }) => css`
        display: grid;
        grid-template-columns: auto auto;

        ${fluidSizing([
            { prop: 'row-gap', values: [10, 10, 10] },
            { prop: 'column-gap', values: [20, 20, 20] },
            { prop: 'margin-left', values: [20, 20, 20] },
            { prop: 'margin-right', values: [20, 20, 20] }
        ])}

        a {
            ${hoverUnderlineAnimation({ theme }, theme.colors.basic.black)};
        }
    `
);
