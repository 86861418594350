import * as Styled from './OxDoctorsAccordion.styled';

import React, { useContext, useEffect, useState } from 'react';
import { OxContainer } from 'src/components/OxContainer';
import { graphql, useStaticQuery } from 'gatsby';
import { OxHorizontalAccordion, TAccordionItem } from 'src/components/OxHorizontalAccordion';
import { OxDoctorProfile } from 'src/components/OxDoctorProfile';
import { Doctor as SanityDoctor } from 'src/services/cms/cms.types';
import { WebsiteDataContext } from 'src/context/WebsiteDataContext';
import geoIPToLocation from 'src/services/clinic/geoIPToLocation';

import { getGatsbyImageDataFromImage } from 'src/services/cms/dataConverter';

export const OxDoctorsAccordion = () => {
    const { geoIpCountry } = useContext(WebsiteDataContext);
    const accordionHeight = [null, 500, 450];
    const contentHeight = [accordionHeight[0], (accordionHeight[1] ?? 500) / 2, accordionHeight[2]];
    const assetHeight = [300, (accordionHeight[1] ?? 500) / 2, accordionHeight[2]];

    const {
        doctors: { nodes: availableDoctors }
    } = useStaticQuery(
        graphql`
            query {
                doctors: allSanityDoctor(
                    sort: { fields: [residentDoctor, nameLast, nameFirst], order: ASC }
                    filter: { isAvailable: { eq: true } }
                ) {
                    nodes {
                        _id
                        name
                        description
                        residentDoctor
                        locations
                        media {
                            primaryMobileImage {
                                asset {
                                    gatsbyImageData(layout: FULL_WIDTH)
                                }
                            }
                            primaryDesktopImage {
                                asset {
                                    gatsbyImageData(layout: FULL_WIDTH)
                                }
                            }
                        }
                    }
                }
            }
        `
    );

    let availableLocations: string[] = [];

    availableDoctors.forEach(
        ({ locations }: { locations: string[] }) =>
            (availableLocations = [...availableLocations, ...locations])
    );

    availableLocations = [...new Set(availableLocations)];

    const geoIpLocation = geoIPToLocation(geoIpCountry ?? '');

    const getLocation = (): string => {
        if (availableLocations.length > 0 && availableLocations.includes(geoIpLocation)) {
            return geoIpLocation;
        }

        return 'London';
    };

    const [selectedLocation, setSelectedLocation] = useState<string>(getLocation);

    useEffect(() => {
        const location = getLocation();

        if (location !== selectedLocation) {
            setSelectedLocation(location);
        }
    }, [geoIpCountry]);

    const calcFade = (num: number, total: number): number => {
        const step = Math.min(0.1, 1 / total);
        return num * step;
    };

    const availableLocationDoctors = availableDoctors.filter(
        ({ locations }: { locations: string[] }) => locations.includes(selectedLocation)
    );

    const accordionItems: TAccordionItem[] = availableLocationDoctors.map(
        (doctor: SanityDoctor, index: number) => {
            const images = [
                {
                    ...getGatsbyImageDataFromImage(doctor.media?.primaryMobileImage),
                    media: `(max-width: 767px)`
                },
                {
                    ...getGatsbyImageDataFromImage(doctor.media?.primaryDesktopImage),
                    media: `(min-width: 768px)`
                }
            ];
            return {
                title: (
                    <Styled.Title fadeAmount={calcFade(index, availableDoctors.length - 1)}>
                        {doctor.name}
                    </Styled.Title>
                ),
                selectTitle: doctor.name,
                content: (
                    <OxDoctorProfile
                        label="Our Doctors"
                        title={doctor.name}
                        description={doctor.description ?? ''}
                        fluidImages={images}
                        isFirst={index === 0}
                        contentHeight={contentHeight}
                        assetHeight={assetHeight}
                    />
                )
            };
        }
    );

    return (
        <OxContainer>
            {availableLocations.length > 1 && (
                <Styled.LocationSwitcher>
                    {availableLocations.map((location, index) => (
                        <React.Fragment key={`${location}`}>
                            <Styled.LocationSwitch
                                type="button"
                                onClick={(): void => setSelectedLocation(location)}
                                disabled={selectedLocation === location}
                            >
                                {location} Doctors
                            </Styled.LocationSwitch>
                            {index !== availableLocations.length - 1 && <Styled.LocationDivider />}
                        </React.Fragment>
                    ))}
                </Styled.LocationSwitcher>
            )}
            <OxHorizontalAccordion
                key={`doctors_accordion_${selectedLocation}`}
                items={accordionItems}
                height={accordionHeight}
            />
        </OxContainer>
    );
};
