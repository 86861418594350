import styled, { css } from 'styled-components';
import { OxImage } from 'src/components/OxImage';
import { createTextStyle } from 'src/utils/createTextStyle';
import { EFonts, EFontWeight } from 'src/config/enums';
import { fluidSizing } from 'src/utils';
import { OxContainer } from 'src/components/OxContainer';
import { OxAnimatedWrapper } from 'src/components/OxAnimatedWrapper';
import { OxLink } from 'src/components/OxLink';

export const Container = styled(OxContainer)``;

export const Quotes = styled.div<{ height?: number }>(
    ({ theme }) => css`
        align-items: center;
        color: ${theme.colors.primary.main};
        display: grid;
        grid-template-areas: 'overlap';
        justify-content: center;
        position: relative;
        overflow: hidden;
    `
);

export const AnimatedWrapper = styled(OxAnimatedWrapper)(
    () => css`
        align-items: center;
        display: flex;
        flex-direction: column;
        grid-area: overlap;
        justify-content: center;
    `
);

export const Quote = styled.div(
    () => css`
        text-align: center;

        ${createTextStyle(EFonts.Hatton, EFontWeight.Medium, [12, 18, 18], [16, 23, 23])}

        ${fluidSizing([{ prop: 'padding-bottom', values: [20, 20, 20] }])}
    `
);

const imageCSS = css`
    width: 100%;
    ${fluidSizing([{ prop: 'max-width', values: [100, 100, 100] }])}
`;

export const Link = styled(OxLink)`
    ${imageCSS}
`;

export const Image = styled(OxImage)`
    ${imageCSS}
`;

export const Source = styled.div`
    text-align: center;
    text-transform: uppercase;

    ${createTextStyle(EFonts.Montserrat, EFontWeight.Bold, [10, 11, 11], [10, 11, 11])}
`;

export const Controls = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;

    ${fluidSizing([{ prop: 'margin-top', values: [20, 20, 20] }])}
`;

export const Dot = styled.button<{ active?: boolean }>(
    ({ theme, active }) => css`
        border: 1px solid ${theme.colors.primary.main};
        border-radius: 100%;

        ${active &&
        css`
            background-color: ${theme.colors.primary.main};
        `}

        ${fluidSizing([
            { prop: 'height', values: [10, 10, 10] },
            { prop: 'margin', values: [10, 10, 10] },
            { prop: 'width', values: [10, 10, 10] }
        ])}
    `
);
