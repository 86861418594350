import * as Styled from './OxContactUs.styled';

import { EInputTheme, OxThemedInput } from 'src/components/OxThemedInput';
import { EOxInputType } from 'src/components/OxInput';
import React, { useContext, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { EModalId } from 'src/components/OxHygene';
import { LayoutContext } from 'src/context/LayoutContext';
import { OxBackToTop } from 'src/components/OxBackToTop';
import { OxButton } from 'src/components/OxButton';
import { OxSuccess } from 'src/components/OxSuccess/OxSuccess';
import { OxTextArea } from 'src/components/OxTextArea';
import { contactCallBack } from 'src/services/api/contact';
import { useBreakpoints } from 'src/hooks';
import { EAlertVariant } from 'src/context/AlertContext';
import { OnyxError } from 'src/helpers/OnyxError';
import { OxPhoneInput } from '../OxPhoneInput';
import { OxRadioList } from 'src/components/OxRadioList';
import { WebsiteDataContext } from 'src/context/WebsiteDataContext';

type TLocation = {
    name: string;
    value: string;
    countryCode: string;
};

export const OxContactUs = (): JSX.Element => {
    const layoutContext = useContext(LayoutContext);
    const websiteContext = useContext(WebsiteDataContext);
    const devices = useBreakpoints();
    const [formData, setFormData] = useState({});
    const [formSubmited, setFormSubmited] = useState(false);
    const data = useStaticQuery(
        graphql`
            query {
                contactUsImage: file(relativePath: { eq: "contact/contact.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
            }
        `
    );

    const locations = websiteContext.locations.map(
        ({ countryCode, displayName, id }) =>
            ({
                name: `${displayName} Clinic`,
                value: `${id}`,
                countryCode: countryCode
            } as TLocation)
    );

    const fluidImages = [
        {
            ...data.contactUsImage.childImageSharp.gatsbyImageData
        }
    ];

    const formInputs = [
        {
            type: EOxInputType.Text,
            name: 'honorific',
            label: 'Title',
            validation: { required: false }
        },
        {
            type: EOxInputType.Text,
            name: 'firstname',
            label: 'First Name*',
            half: true,
            validation: { required: true }
        },
        {
            type: EOxInputType.Text,
            name: 'lastname',
            label: 'Last Name*',
            half: true,
            validation: { required: true }
        },
        {
            type: EOxInputType.Email,
            name: 'email',
            label: 'Email Address*',
            validation: { required: true }
        }
    ];

    const onFormSubmit = async (data: FormData): Promise<void> => {
        const result = await contactCallBack({
            honorific: data.get('honorific') as string,
            firstname: data.get('firstname') as string,
            lastname: data.get('lastname') as string,
            email: data.get('email') as string,
            clinicId: parseInt(data.get('clinicId') as string),
            phone: data.get('phone') as string,
            query: data.get('query') as string,
            newsletter: !!data.get('newsletter')
        });
        if (result) {
            if (result.error || result.errors || result.status === 'error') {
                if (Array.isArray(result.errors) && result.errors[0]) {
                    if (result.errors[0].errorType === 'INVALID_EMAIL') {
                        throw new OnyxError({
                            type: EAlertVariant.Error,
                            header: 'ERROR ALERT: OCU1',
                            title: 'Invalid email address',
                            message:
                                'The email address you entered appears to be invalid. Please check your details and try again, if the problem persists please contact us.'
                        });
                    } else {
                        throw new OnyxError({
                            type: EAlertVariant.Error,
                            header: 'ERROR ALERT: OCU2',
                            title: 'Something went wrong',
                            message:
                                'Please check your details and try again, if the problem persists please contact us.'
                        });
                    }
                }
            } else {
                setFormData({
                    name: data.get('firstname'),
                    surname: data.get('lastname'),
                    query: data.get('query')
                });
                setFormSubmited(true);
            }
        } else {
            throw new OnyxError({
                type: EAlertVariant.Error,
                header: 'ERROR ALERT: OCU3',
                title: 'Something went wrong',
                message:
                    'Please check your details and try again, if the problem persists please contact us.'
            });
        }
    };

    const initialLocation = locations.find(
        (location) => location.countryCode === (websiteContext.geoIpCountry ?? 'GB')
    );

    const [selectedCountryCode, setSelectedCountryCode] = useState<string>('');

    return (
        <Styled.Container>
            <OxSuccess
                show={formSubmited}
                formData={formData}
                hideSummary={true}
                type="contactUs"
            />
            <Styled.ContactUsContainer isSuccess={formSubmited}>
                <Styled.BlockText>Contact Us</Styled.BlockText>
                <Styled.BlockText>Get in Touch to Learn More</Styled.BlockText>
                <Styled.ImageFormContainer>
                    <Styled.FormContainer>
                        <OxThemedInput theme={EInputTheme.GoldLight}>
                            <Styled.FormWrapper>
                                <Styled.Form id="contact-form" onFormSubmit={onFormSubmit}>
                                    {({ submitButtonProps }: { submitButtonProps: any }) => (
                                        <>
                                            <Styled.ClinicContainer>
                                                <Styled.ClinicSelectorTitle>
                                                    Please select a clinic
                                                </Styled.ClinicSelectorTitle>
                                                <OxThemedInput theme={EInputTheme.GoldTransparent}>
                                                    {locations.length > 0 && (
                                                        <OxRadioList
                                                            required
                                                            name="clinicId"
                                                            items={locations}
                                                            direction="row"
                                                            radioShape="circle"
                                                            initialValue={initialLocation?.value}
                                                            fullLeftInput={true}
                                                            onValueChange={(val) =>
                                                                setSelectedCountryCode(
                                                                    locations.find(
                                                                        (location) =>
                                                                            location.value === val
                                                                    )?.countryCode ?? ''
                                                                )
                                                            }
                                                        />
                                                    )}
                                                </OxThemedInput>
                                            </Styled.ClinicContainer>

                                            <OxThemedInput theme={EInputTheme.GoldTransparent}>
                                                {formInputs.map(
                                                    ({
                                                        half,
                                                        hide,
                                                        label,
                                                        name,
                                                        type,
                                                        validation,
                                                        value,
                                                        onInit
                                                    }) => (
                                                        <Styled.Input
                                                            key={name}
                                                            type={type}
                                                            name={name}
                                                            id={`in_${name}`}
                                                            placeholder={label}
                                                            half={half}
                                                            hide={hide}
                                                            value={value}
                                                            onInit={onInit}
                                                            {...validation}
                                                        />
                                                    )
                                                )}
                                            </OxThemedInput>
                                            <OxThemedInput theme={EInputTheme.GoldTransparent}>
                                                <OxPhoneInput
                                                    key={`phone_input_${selectedCountryCode}`}
                                                    name="phone"
                                                    placeholder="Telephone"
                                                    initialCountryCode={selectedCountryCode}
                                                    required
                                                />
                                            </OxThemedInput>
                                            <OxThemedInput theme={EInputTheme.GoldTransparent}>
                                                <OxTextArea
                                                    placeholder="Query*"
                                                    name="query"
                                                    id={`in_query`}
                                                    maxLength={500}
                                                    {...{ required: true }}
                                                />
                                            </OxThemedInput>
                                            <Styled.Checkbox
                                                label="* I agree to the full"
                                                labelLinkTitle="terms and conditions"
                                                labelLinkOnClick={(): void =>
                                                    layoutContext.setModalId(EModalId.Terms)
                                                }
                                                id="terms_checkbox"
                                                name="terms"
                                                value="terms"
                                                shape="roundedSquare"
                                                required
                                            />
                                            <Styled.Checkbox
                                                label="Receive Ouronyx newsletter"
                                                id="newsletter_checkbox"
                                                name="newsletter"
                                                value="newsletter"
                                                shape="roundedSquare"
                                                initialChecked
                                            />
                                            <OxThemedInput theme={EInputTheme.BackgroundGold}>
                                                <OxButton type="submit" icon {...submitButtonProps}>
                                                    SUBMIT
                                                </OxButton>
                                            </OxThemedInput>
                                        </>
                                    )}
                                </Styled.Form>
                            </Styled.FormWrapper>
                        </OxThemedInput>
                    </Styled.FormContainer>
                    <Styled.CustomImage fluidImages={fluidImages} />
                </Styled.ImageFormContainer>
            </Styled.ContactUsContainer>
            {(devices.xs || devices.s) && <OxBackToTop />}
        </Styled.Container>
    );
};
