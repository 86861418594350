import * as Styled from './OxTextCallout.styled';

import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { OxContainer } from 'src/components/OxContainer';

type TProps = {
    text: string;
};

export const OxTextCallout = ({ text }: TProps) => {
    return (
        <OxContainer>
            <Styled.Wrapper>{ReactHtmlParser(text)}</Styled.Wrapper>
        </OxContainer>
    );
};
